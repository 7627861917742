.searchPanel {
  padding: 20px;
  background-color: white;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.searchBar {
  position: relative;
}
.searchBar input {
  padding: 10px;
  width: 100%;
  resize: horizontal;
  overflow: auto;
}
.searchBar i {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  font-size: 18px;
}
