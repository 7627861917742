.user-card-detail {
    display: flex;
    border: 1px solid #ccc;
    box-shadow: 0px 4px 6px rgba(171, 81, 81, 0.1);
    border-radius: 15px;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding: 45px;
    position: relative;
}

.user-card-img {
    padding-left: 40px;
}

.user-card-img img {
    width: 130px;
    height: 130px;
    border-radius: 100px;
}

.user-card-detail h4 {
    text-transform: uppercase;
    font-size: 20px;
    font-weight: bold;
}

.user-card-div {
    margin-top: 20px;
    width: 100px;
    padding: 4px 0;
    font-size: 13px;
    text-align: center;
    background-color: pink;
    border-radius: 15px;
}

/* BADGES  */
.user-card-badge {
    position: absolute;
    bottom: 1px;
    right: 1px;
    color: rgb(40, 40, 40);
    border-radius: 8px;
    padding: 4px 10px;
    font-size: 13px;
}

.docs-verify-badge{
    position: absolute;
    top:  1px;
    right: 1px;
    color: rgb(40, 40, 40);
    border-radius: 8px;
    padding: 4px 10px;
    font-size: 13px;

}
.user-verify {
    background-color: rgb(247, 247, 77);

}

.user-unverify {
    
    background-color: pink;

}
.user-reject {
    background-color: pink;

}
.user-card-badgess {
    position: absolute;
    bottom: 1px;
    right:120px;
    color: rgb(40, 40, 40);
    border-radius: 8px;
    padding: 4px 10px;
    font-size: 13px;
}

.user-verify {
    /* background-color: rgb(247, 247, 77); */
    background-color: #64f364;
    color: rgb(78, 78, 78);

}

.user-unverify {
    background-color: pink;

}

/* ACTIVE BTNS  */
.active-btns {
    text-align: center;
    border-radius: 15px;
    margin: 30px 0;
    border: 1px solid #ccc;
    box-shadow: 0px 4px 6px rgba(171, 81, 81, 0.1);
    padding: 25px 0px;
    width: 100%;
}

.active-btns h4 {
    text-transform: uppercase;
}

.active-btn {
    padding: 15px;
}


.active-btn-btn {
    margin: 20px;
    border: none;
    outline: none;
    width: 120px;
    height: 30px;
    font-size: 15px;
    text-align: center;
    border-radius: 20px;
    background-color: rgb(247, 247, 77);
}

.active-btn-btn i {
    padding: 4px;
}
.active-btn-disable{
    background-color: rgb(219, 219, 219);
    margin: 20px;
    border: none;
    cursor: not-allowed;
    outline: none;
    width: 120px;
    height: 30px;
    font-size: 15px;
    text-align: center;
    border-radius: 20px;


}
.active-btn-disable i {
    padding: 4px;
}