/* CategorySection.css */
.category-section {
  background-color: rgb(249, 247, 247);
  padding: 25px 0px;
  width: 100%;

}

.category-list {
  list-style-type: none;
  padding: 0;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.category-item {
  margin: 0;
  padding: 0;
}
.category-item a {
  text-decoration: none;
  color: black;
  font-weight: normal;
  transition: 0.3s;
}

.category-item a:hover {
  color: #ff5722;
}
::first-letter {
  text-transform: capitalize;
}

.navbar-brand {
  font-size: 1.4em;
}

.navbar-dark .navbar-nav a.nav-link {
  color: #ffffff;
  font-size: 1.1em;
}

.dropdown-menu {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  border: none;
  border-radius: 0;
  padding: 0.7em;
  left: -450px;
}

@media only screen and (min-width: 992px) {
  .dropdown:hover .dropdown-menu {
    display: flex;
  }

  .dropdown-menu.show {
    display: flex;
  }
}

.dropdown-menu ul {
  list-style: none;
  padding: 0;
}

.dropdown-menu li .dropdown-item {
  color: gray;
  font-size: 1em;
  padding: 0.5em 1em;
}

.dropdown-menu li .dropdown-item:hover {
  background-color: #f1f1f1;
}

.dropdown-menu li:first-child a {
  font-size: 1.1em;
  text-transform: uppercase;
  color: #516beb;
}

.dropdown-menu li:first-child a:hover {
  background-color: #f1f1f1;
}

@media only screen and (max-width: 992px) {
  .dropdown-menu.show {
    flex-wrap: wrap;
    max-height: 350px;
    overflow-y: scroll;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1140px) {
  .dropdown:hover .dropdown-menu {
    width: 40vw;
    flex-wrap: wrap;
  }
}

.dropdown-menu {
  border-radius: 0;
  border: none;
  padding: 0.5em;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.23);
}

.dropdown-menu ul {
  list-style: none;
  padding: 0;
}

.dropdown-menu li a {
  color: gray;
  padding: 0.5em 1em;
}

.dropdown-menu li:first-child a {
  font-weight: bold;
  font-size: 1.1em;
  color: #84cdea;
}

@media screen and (min-width: 993px) {
  .dropdown:hover .dropdown-menu {
    display: flex;
  }

  .dropdown-menu.show {
    display: -ms-flexbox;
  }
}

@media screen and (max-width: 992px) {
  .dropdown-menu.show {
    max-height: 60vh;
    overflow-y: scroll;
  }
}
@media screen and (max-width:770px){
  .category-item{
    display: none;
  }
  
}

@media screen and (max-width: 749px) {
  .category-section {
    background-color: rgb(249, 247, 247);
    padding: 25px 5px;
    width: 100%;
    background-color: rgb(245, 240, 240);
  }

  .category-list {
    list-style-type: none;
    padding: 0;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }
  .category-item {
    margin: 0;
    padding: 0;
  }
  .dropdown-menu {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.8), 0 1px 3px rgba(0, 0, 0, 0.23);
    border: none;
    border-radius: 0;
    padding: 0.3em;
  }
}
