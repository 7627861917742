.spinners {
    margin: 200px 0;
  }
  .spinner-img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100px;
  }
  .spinners span {
    text-align: center;
    color: grey;
    display: block;
  }
  