.store-section {
    border-radius: 15px;
    border: 1px solid #ccc;
    box-shadow: 0px 4px 6px rgba(171, 81, 81, 0.1);
    padding: 25px;

}

.store-section h4 {
    margin: 0 0 30px 0;
    text-transform: uppercase;
    text-align: center;

}

.store-section-img {
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.store-section-img img {
    margin: 10px;
    width: 270px;
}

.store-section-details {
    padding: 20px 25px;
}

.store-section-three-details {
    display: flex;
    justify-content: space-between;
    align-content: center;
    padding: 20px;
}

.store-section-three-details div {
    background-color: rgb(121, 196, 121);
    padding: 3px 35px;
    border-radius: 7px;
}

.store-section-three-details span {
    display: block;
    color: rgb(10, 90, 10);
}

.store-product {
    display: flex;
    margin: 20px 0;
    justify-content: center;
}

.store-product-img img {
    width: 170px;
    height: 180px;
}

.store-product-details {
    padding: 10px 30px;
}