.user-document {
    width: 900px;
    border: 1px solid #ccc;
    box-shadow: 0px 4px 6px rgba(171, 81, 81, 0.1);
    border-radius: 15px;
}

.user-document h4 {
    padding-top: 30px;
    text-align: center;
    text-transform: uppercase;
}

.user-document-map {
    box-shadow: 5px 5px 5px 5px rgba(171, 81, 81, 0.1);
    border: 1px solid #898989;
    margin: 30px;
    border-radius: 15px;
    padding: 20px;
}


/* UPPER SECTION  */
.user-document-user {
    padding: 50px 0;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: row;
}

.user-document-user-img {
    position: relative;
}

.user-document-user-img img {
    border-radius: 100px;
    cursor: pointer;
    width: 130px;
    height: 130px;
    transition: 0.5s;
}




.user-document-user-img:hover::after {
    content: "View Profile";
    position: absolute;
    bottom: 120px;
    transition: 0.5s;
    color: white;
    left: 110px;
    line-height: 24px;
    /* height: 25px; */
    width: 90px;
    font-size: 12px;
    padding: 4px 0;
    text-align: center;
    background-color: rgb(118, 116, 116);
}

.user-document-user-detail h6 {
    font-size: 17px;
}

.user-document-user-detail h6 span {
    color: rgb(63, 61, 61);
    font-weight: 400;
}


.user-document-user-bages button {
    margin: 10px;
    border: none;
    outline: none;
    width: 100px;
    border-radius: 15px;
}

.user-document-user-bages button i {
    padding-right: 5px
}

/* lower section */

.user-document-detail {
    display: flex;
    padding-top: 30px;
    align-items: center;
    justify-content: space-evenly;
}

/* .user-document-detail img {
    width: 370px;
    height: 250px;
    border: 2px solid rgb(163, 163, 163);
} */















/* Style the image slider container */

.user-document-images {
    max-width: 800px;
    margin: 0 auto;
    display: flex;
    position: relative;
    align-items: center;
    overflow: hidden;
}

.user-document-images-for-dashboard {
    margin: 0 auto;
    max-width: 620px;
    padding: 20px;
    border: 1px solid #ccc;
    box-shadow: 0px 4px 6px rgba(171, 81, 81, 0.1);
    border-radius: 15px;
    display: flex;
    position: relative;
    align-items: center;
    overflow: hidden;
}



.user-docs-image-slider {
    flex-grow: 1;
    display: flex;
    overflow: hidden;
    overflow-x: auto;
    scroll-behavior: smooth;
    white-space: nowrap;
}


.user-docs-carousel-image {
    flex: 0 0 auto;
    margin-right: 10px;
}

.user-docs-carousel-image img {
    border: 2px solid rgb(163, 163, 163);
    height: 270px;
    border-radius: 15px;
    width: 393px;

}

.user-docs-carousel-image-dashboard img {
    border: 2px solid rgb(163, 163, 163);
    height: 250px;
    margin-inline-end: 10px;
    border-radius: 10px;
    width: 285px;
}