@font-face {
  font-family: "Clash-Display-Bold";
  /*Can be any text*/
  src: local("ClashDisplay-Bold"),
    url("/fonts/ClashDisplay-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Clash-Display-Semibold";
  /*Can be any text*/
  src: local("ClashDisplay-Semibold"),
    url("/fonts/ClashDisplay-Semibold.ttf") format("truetype");
}

@font-face {
  font-family: "Clash-Display-Regular";
  /*Can be any text*/
  src: local("ClashDisplay-Regular"),
    url("/fonts/ClashDisplay-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Clash-Display-Medium";
  /*Can be any text*/
  src: local("ClashDisplay-Medium"),
    url("/fonts/ClashDisplay-Medium.ttf") format("truetype");
}

.footer-head-font {
  color: #000;
  text-align: center;
  font-family: "Clash-Display-Medium";
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 42px;
  /* 131.25% */
  text-transform: capitalize;
}

li {
  color: #000;
  font-family: "Clash-Display-Medium";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  /* 162.5% */
  text-transform: lowercase;
}

.footer-copyright {
  display: flex;
  font-family: "Clash-Display-Medium";
  width: 1090px;
  height: 104px;
  padding: 0px 358px 0px 369px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.foot-link {
  margin-right: 40px;
}

.foot-legal {
  margin-right: 50px;
}

.footer .link {
  display: flex;
  justify-content: start;
  margin-top: 10px;
  flex-direction: column;
  align-items: center;
}

.footer .link ul {
  padding: 0;
}

.footer .link ul li {
  list-style: none;
  margin-top: 6px;
}

.footer .link ul li span {
  margin-right: 5px;
}

.footer .link ul li span img {
  width: 20px;
  height: 20px;
}

@media screen and (max-width: 600px) {
  .faq-head {
    font-size: 30px;
    text-align: center;
    width: auto;
  }

  .foot-link,
  .foot-legal {
    margin-right: 0;
  }
}
