@font-face {
    font-family: "Clash-Display-Bold";
    /*Can be any text*/
    src: local("ClashDisplay-Bold"),
        url("/fonts/ClashDisplay-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Clash-Display-Semibold";
    /*Can be any text*/
    src: local("ClashDisplay-Semibold"),
        url("/fonts/ClashDisplay-Semibold.ttf") format("truetype");
}

@font-face {
    font-family: "Clash-Display-Regular";
    /*Can be any text*/
    src: local("ClashDisplay-Regular"),
        url("/fonts/ClashDisplay-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Clash-Display-Medium";
    /*Can be any text*/
    src: local("ClashDisplay-Medium"),
        url("/fonts/ClashDisplay-Medium.ttf") format("truetype");
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo2 {

    background-image: url("../images/bg-ovals.png");
    background-position: right;
    background-size: contain;
    background-repeat: no-repeat;
    height: auto;
    max-height: 40rem;
}

.logo-section-right {
    float: right;
    position: relative;
}

.logo1 {
    width: 100%;
    max-width: 35vw;
}

.logo-section {
    display: flex;
    flex-direction: column;
    /* background: radial-gradient(#AAFF55 15%, white 60%, white 40%);
    background-position: top;
    background-size: 700px 300px; */
}

.logo-section button {
    font-family: "Clash-Display-Regular";
    width: 180px;
    height: 60px;
    flex-shrink: 0;
    border-radius: 4px;
    background: #000;
}

.logo-section h2 {
    font-weight: 900;
}

.gradient1 {
    width: 284px;
    height: 153px;
    place-self: center;
    flex-shrink: 0;
    border-radius: 284px;
    background: #AF5;
    filter: blur(50px);
    position: absolute;
    z-index: -1;
}

.gradient2 {
    top: 179px;
    left: 296px;
    width: 290px;
    height: 153px;
    flex-shrink: 0;
    border-radius: 290px;
    background: #FDE598;
    filter: blur(50px);
    position: absolute;
    z-index: -2;
}

.gradient3 {
    width: 397.428px;
    height: 214.107px;
    transform: rotate(150deg);
    flex-shrink: 0;
    border-radius: 397.428px;
    background: #AF5;
    filter: blur(51.5772819519043px);
    position: absolute;
    left: 140px;
    bottom: 150px;
    z-index: -1;
}

.gradient4 {
    width: 375.382px;
    height: 242.974px;
    flex-shrink: 0;
    border-radius: 375.382px;
    background: #AF5;
    filter: blur(51.5772819519043px);
    position: absolute;
    transform: translate(-50%, -50%);
    top: 20%;
    left: 50%;
    z-index: -1;
}

.p-relate {
    position: relative;
}

.gradient5 {
    width: 359.527px;
    height: 189.682px;
    flex-shrink: 0;
    border-radius: 359.527px;
    background: #FDE598;
    filter: blur(51.5772819519043px);
    position: absolute;
    transform: translate(-50%, -50%);
    top: 25%;
    left: 40%;
    z-index: -1;
}

.gradient6 {
    width: 352.089px;
    height: 189.682px;
    flex-shrink: 0;
    border-radius: 352.089px;
    background: #AF5;
    filter: blur(51.5772819519043px);
    position: absolute;
    transform: translate(-50%, -25%);
    top: 30%;
    left: 40%;
    z-index: -1;
}

.gradient7 {
    width: 292.959px;
    height: 157.826px;
    flex-shrink: 0;
    border-radius: 292.959px;
    background: #AF5;
    filter: blur(51.5772819519043px);
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 40%;
    z-index: -1;
}

.gradient8 {
    width: 299.148px;
    height: 157.826px;
    flex-shrink: 0;
    border-radius: 299.148px;
    background: #FDE598;
    filter: blur(51.5772819519043px);
    position: absolute;
    transform: translate(-50%, -50%);
    top: 60%;
    left: 40%;
    z-index: -2;
}


.gradient9 {
    width: 359.527px;
    height: 189.682px;
    transform: rotate(-39.871deg);
    flex-shrink: 0;
    border-radius: 359.527px;
    background: #FDE598;
    filter: blur(51.5772819519043px);
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 65%;
    z-index: -2;
}

.gradient10 {
    width: 352.089px;
    height: 189.682px;
    transform: rotate(-39.871deg);
    flex-shrink: 0;
    border-radius: 352.089px;
    background: #AF5;
    filter: blur(51.5772819519043px);
    position: absolute;
    transform: translate(-50%, -50%);
    top: 60%;
    left: 55%;
    z-index: -1;
}

.gradient11 {
    width: 292.959px;
    height: 157.826px;
    flex-shrink: 0;
    border-radius: 292.959px;
    background: #AF5;
    filter: blur(51.5772819519043px);
    position: absolute;
    transform: translate(-50%, -50%);
    top: 45%;
    left: 30%;
    z-index: -1;
}

.gradient12 {
    width: 299.148px;
    height: 157.826px;
    flex-shrink: 0;
    border-radius: 299.148px;
    background: #FDE598;
    filter: blur(51.5772819519043px);
    position: absolute;
    transform: translate(-50%, -50%);
    top: 60%;
    left: 30%;
    z-index: -2;
}



/* .logo-section h2 {
    background: url("../images/background\ gradient.png") no-repeat center;
    background-size:auto;
    font-weight: 900;
    overflow: visible;
} */

.logo-section p {
    color: rgba(0, 0, 0, 0.50);
    font-family: "Clash-Display-Medium";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    /* 155.556% */
    text-transform: capitalize;
}

.container {
    margin-bottom: 4rem;
    align-items: center !important;
    position: relative;
}

.header-btn {
    font-family: "Clash-Display-Regular";
    width: 180px;
    height: 60px;
    flex-shrink: 0;
    border-radius: 4px;
    background: #000;
    color: #fff;
}

.headding {
    text-align: center;
    font-size: 10px;

}

.headding1 {
    text-align: center;
    font-size: 28px;
    font-weight: 800;
}

/* .commen-hedding{
    background: radial-gradient(#AAFF55 15%, white 40%, white 40%);
    width: 300px !important;
    margin: auto;
    height: 50px;
    text-align: center;
} */
.commen {
    position: relative;
    background-image: url("../images/bg-ovals.png");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.commen-hedding {
    position: absolute;
    background-image: url("../images/bg-ovals.png");
    background-position: unset;
    background-size: contain;
    background-repeat: no-repeat;
    height: 40vh;
    top: 2px;
    left: 55%;
    transform: translate(-20%, -22%);


}

.commen-hedding1 {
    text-align: center;
    z-index: 1000000;
    margin: 20px;
}


.main-heading {
    color: #000;
    font-family: "Clash-Display-Bold";
    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    line-height: 64px;
    /* 106.667% */
    text-transform: capitalize;
}

.cat-head {
    color: #000;
    text-align: center;
    font-family: "Clash-Display-Bold";
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    text-transform: capitalize;
}

.cat-slide-head {
    color: #000;
    text-align: center;
    font-family: "Clash-Display-Semibold";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    /* 155.556% */
    letter-spacing: 2.88px;
    text-transform: uppercase;
}

.many-more-btn {
    margin-top: 3rem;
    font-family: "Clash-Display-Regular";
    width: 180px !important;
    float: right !important;
    height: 60px;
    flex-shrink: 0;
    border-radius: 4px;
    background: #000;
    color: #fff;
}


.leftimg {
    width: 100%;
}

.feature {
    color: #FFBE00;
    text-align: left;
    font-family: "Clash-Display-Regular";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    /* 155.556% */
    letter-spacing: 2.88px;
    text-transform: uppercase;
}


.feature-head {
    color: #000;
    font-family: "Clash-Display-Bold";
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    /* 100% */
    text-transform: capitalize;
}

.features-sub-head {
    color: #000;
    font-family: "Clash-Display-Semibold";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    /* 155.556% */
    text-transform: capitalize;
    margin: 0 !important;
}

.advantage-sub-head {
    font-size: 28px;
}

.para-common {
    color: rgba(0, 0, 0, 0.50);
    font-family: "Clash-Display-Medium";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    /* 155.556% */
    text-transform: capitalize;
}

.experience {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 20px 0;
}

.testi-mono {
    color: #000;
    text-align: center;
    font-family: "Clash-Display-Bold";
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    /* 100% */
    text-transform: capitalize;
}

.buy-sell {
    color: #000;
    font-family: "Clash-Display-Medium";
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    /* 100% */
    text-transform: capitalize;
}

.faq-head {
    color: #000;
    font-family: "Clash-Display-Bold";
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    /* 100% */
    text-transform: capitalize;
    width: 497px;
}

li {
    font-size: 10px;
    color: #8F8F8F;
}

.span {
    /* width: 35rem; */
    color: #8F8F8F;
    font-size: 16px;
}

.cards1 {
    height: 18.8vh;
    width: unset;
    margin: 9px;
    border-radius: 8px;
    padding: 18px 18px 18px 26px;
}

.cards2 {
    height: 19.4vh;
    margin: 9px;
    border-radius: 8px;
    padding: 18px 18px 18px 26px;
    background-color: #FFBE00;
    color: white;
    width: 535px;
    height: 181px;
    flex-shrink: 0;
    border-radius: 8px;
    background: #FFBE00;
}

.faq-name {
    font-family: "Clash-Display-Semibold";
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    /* 100% */
    text-transform: capitalize;
}

.cards1 p {
    width: 95%;
    width: 95%;
    color: rgba(0, 0, 0, 0.50);
    text-align: justify;
    font-family: "Clash-Display-Medium";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
}

.cards2 p {
    width: 95%;
    color: #FFF;
    text-align: justify;
    font-family: "Clash-Display-Regular";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    /* 155.556% */
}

.bannerimg {
    width: 100%;
}



@media screen and (max-width: 600px) {
    .logo-section h2 {
        width: 100%;
        font-size: 28px;
        font-weight: 900;
        line-height: 30px;
    }

    .logo-section-right{
        margin-bottom: 2rem;
    }

    .headding {
        text-align: center;
        font-size: 12px;

    }

    .headding1 {
        text-align: center;
        font-size: 38px;
        font-weight: 800;
    }

    .gradient2 {
        place-self: center;
        left: 0;
    }

    .logo2 {
        margin-left: 40px;
        width: 100%;
        max-width: 15rem;
    }

    .feature{
        text-align: center;
    }

    .feature-head{
        font-size: 30px;
    }

    .advantage-sub-head {
        font-size: 18px;
    }

    .experience img{
        width: 25px;
    }

    .testi-mono{
        font-size: 30px;
    }

    .gradient3{
        transform: translate(-50%, -50%);
        left: 50%;
    }

    .gradient11{
        top: 15%;
        left: 50%;
    }

    .gradient12{
        top: 50%;
        left: 50%;
    }

    .container, .row, .order-2 {
        width: 100%;
        text-align: center;
    }

    .cards2{
        width: 100%;
    }

    .container{
        margin-bottom: 0;
    }

    .experience{
        justify-content: center;
    }


}