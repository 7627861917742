.admin-search-results {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 10px;

}

.admin-search-result {
    margin-top: 15px;
    margin: 10px;
    padding: 20px;
    transform: 0.3s;
    cursor: pointer;
    border-radius: 15px;
    align-items: center;
    border: 1px solid #ccc;

}

.admin-search-result:hover {
    transform: 0.3s;
    box-shadow: 0 7px 15px 0 rgba(0, 0, 0, .13), 0 1px 4px 0 rgba(0, 0, 0, .11);

}


.admin-search-result-details {
    margin-top: 10px;
}

.admin-search-result-details button {
    cursor: pointer;
    background-color: black;
    color: white;
    border: none;
    outline: none;
    border-radius: 5px;
    font-size: 11px;
    padding: 4px 8px;
    font-weight: 400;
}

.admin-search-result-img img {
    width: 180px;
    height: 190px;
}

.search-user-not-found {
    font-weight: 600;
    font-size: 25px;
}