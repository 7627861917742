.admin-content-posts {
    padding: 18px 0;
}

.admin-content-postunder {
    padding: 15px;
    border-radius: 15px;
    border: 1px solid #ccc;
    box-shadow: 0px 4px 6px rgba(171, 81, 81, 0.1);

}

.admin-content-post {
    display: flex;
    cursor: pointer;
    justify-content: space-evenly;
    border: 1px solid rgb(224, 224, 224);
    padding: 20px;
    background-color: #f3f3f3;
    margin: 10px 0;
    border-radius: 15px;

}

.admin-content-post:hover {
    box-shadow: 1px 1px 1px 0px rgba(20, 0, 0, 0.1);

}

.admin-content-post-img img {
    width: 80px;
    height: 80px;
    border-radius: 50px;


}

.admin-content-post-detail {
    padding-left: 20px;
    max-width: 200px;
}

.admin-content-post-detail p {
    font-size: 13px;
}

.admin-content-posts h6 {
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 20px;
}

.admin-content-detail-btn button {
    border: none;
    outline: none;
    font-size: 12px;
    padding: 2px 10px;
    margin-inline-end: 5px;
    border-radius: 20px;
    transition: 0.3s;
    color: rgb(7, 86, 151);
    background-color: rgb(120, 182, 241);
}

.admin-content-detail-btn button:hover {
    transition: 0.3s;
    background-color: rgb(177, 198, 219);

}

.admin-content-detail-btn button i {
    margin: 2px;
}
.post-not-found{
    font-weight: 600;
    font-size: 25px;
}