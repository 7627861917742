.product-section {
    padding: 25px;
    border-radius: 15px;
    border: 1px solid #ccc;
    box-shadow: 0px 4px 6px rgba(171, 81, 81, 0.1);
    max-width: 700px;

}

.product-section h4 {
    margin: 0 0 30px 0;
    text-transform: uppercase;
    text-align: center;

}

.product-section-img {
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.product-section-img img {
    width: 250px;
    margin: 15px;
    border-radius: 20px;
    height: 200px;
}

.product-section-some-details {
    display: flex;
    padding: 20px 20px;
    justify-content: space-between;
}

.product-section-some-details div i {
    margin-inline-end: 1px;
    font-size: 21px;

}

.product-section-name-price {
    padding: 0 20px;
}

.product-section-name-price h6 {
    font-size: 18px;
}

.product-section-name-price span {
    display: block;

}

.product-section-name-price span i {
    margin-inline-end: 6px;
    font-size: 20px;
    text-align: center;
}

.section-price-name {
    margin: 0px 0 10px 0;

}

.section-dec-detail {
    margin: 20px 10px;
}

.section-more-detail {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    margin: 30px 0;

}

.section-more-detail div p {
    font-size: 14px;
    text-transform: uppercase;
    color: grey;

}