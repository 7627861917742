.nav-sidebar {
    height: 100%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    padding: 10px;
    display: flex;
    flex-direction: column;
}

.nav-sidebar-logo {
    margin-bottom: 20px;
}

.nav-sidebar-logo img {
    margin: auto;
    display: flex;
}

.nav-sidebar-list {
    list-style: none;
    padding: 0;
}

.nav-sidebar-list li {
    padding: 10px 0;
    font-size: 20px;
    cursor: pointer;
    transition: 0.3s;
}

/* FOR ACTIVE PATH  */
.nav-sidebar-list .active {
    border-right: 6px solid #197F3C;
    background-color: rgb(227, 238, 237);

}

.nav-sidebar-list .active a {
    color: #197F3C;


}

.nav-sidebar-list li a {
    text-decoration: none;
    color: black;
    text-transform: none;
}

.nav-sidebar-list li i {
    margin: 10px;
    font-size: 20px;
}