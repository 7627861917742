.contactDetail {
    width: 950px;
    height: 100%;
}

.contactDetail form {
    margin: auto;
    max-width: 1150px;
}

.input-group {
    margin-bottom: 30px;
}

.blogform-category {
    margin-bottom: 30px;
}

.input-group span {
    font-size: 20px;
    display: block;
    margin: 10px 0;
}

.blogform-category span {
    font-size: 20px;
    display: block;
    margin: 10px 0;
}

.contactDetail input,
textarea {
    width: 100%;
    border: 2px solid #225887;
    padding: 10px;
}