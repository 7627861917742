.search-top-bar {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12);
    display: flex;
    justify-content: space-between;
    padding: 15px 30px;
}

.top-search-bar {
    display: flex;
    border:1px solid rgb(165, 165, 165);
    border-radius: 20px;
    align-items: center;
    /* padding-right:20px; */
    padding-left:20px;
}
.top-search-bar i{
    background-color: black;
    color: white;
    padding: 10px 15px;
    font-weight: 600;
    cursor: pointer;
    border-end-end-radius:20px ;
    border-start-end-radius:20px ;

}

.top-search-icon-search {
    font-size: 20px;
    padding-right: 15px;
    color: #727272;
}

.top-search-icon {
    color: #727272;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 250px;
}

.top-search-icon a img {
    width: 33px;
    height: 32px;
    cursor: pointer;
    border-radius: 50px;

}
.top-search-icon button{
    background-color: black;
    color: white;
    border-radius: 5px;
    font-size: 16px;
    padding: 4px 8px;
    font-weight: 400;


}
.top-search-bar input[type="text"] {
    height: 40px;
    border: none;
    outline: none;
    background: transparent;
    border: none;
    width: 400px;
    font-size: 16px;
}