.outer-card {

    overflow-x: auto;
    white-space: nowrap;
    background-color: white;
    height: 221px;
    width: 55%;
    /* border:2px solid red; */
    display: flex;
    border: 1px solid #ccc;
    flex-direction: column;
    /* margin: 17px; */
    padding: 15px;
    border-radius: 15px;
    box-shadow: 0px 4px 6px rgba(171, 81, 81, 0.1);
}

.outer-card h4 {
    text-align: center;
    text-transform: uppercase;
}

.heading {
    font-size: 23px;
}