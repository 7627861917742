/* CSS for the ArticleSection component */
@import url("https://fonts.googleapis.com/css?family=DM+Sans:400,500,700|Source+Sans+Pro:300,400,600,700&display=swap");

.article-section {
  background-color: #fff;
  width: 100%;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  margin: 20px 0;
  padding: 40px 50px;
  position: relative;
}

.article-section a {
  color: black;
  text-decoration: none;
}

.article-section .article-span {
  font-weight: bold;
  padding-bottom: 3px;
  border-bottom: 3px solid green;
}

.article-section .article-span:hover {
  color: grey;
}

.article-section h2 {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.article-section p {
  font-size: 1rem;
  line-height: 1.4;
  margin-bottom: 15px;
}

.more-article-number {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  margin: 50px 0;
}

.more-article-number div {
  text-align: center;
  transition: 0.5s;
  margin: 0 10px;
  padding: 5px 15px;
  font-weight: bold;
}


.articlenumber {
  background-color: black;

}

.more-article-number div a {
  text-decoration: none;
}

.more-article-number div:hover {
  background-color: black;
  transition: 0.5s;
  color: white;
}


.read-more,
.read-less {
  cursor: pointer;
  color: #a689ff;
  text-decoration: underline;
}

.action-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.action-buttons button {
  margin-left: 10px;
  padding: 5px 10px;
  border: none;
  background-color: #545454;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s;
}

.action-buttons button:hover {
  background-color: black;
}

.article-image {
  max-width: 90%;
  height: auto;
  margin-bottom: 10px;
  display: flex;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .article-section {
    padding: 10px;
  }

  .article-section h2 {
    font-size: 1.2rem;
  }

  .action-buttons {
    justify-content: space-between;
  }
}