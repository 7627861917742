.store-detail-card {
    width: 222px;
    height: 134px;
    flex-shrink: 0;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 2px 2px rgba(0, 0, 0, 0.15), 0 4px 4px rgba(0, 0, 0, 0.15), 0 8px 8px rgba(0, 0, 0, 0.15);
    margin: 11px;
    padding: 12px;
}

.store-card-image {
    display: block;
    width: 70px;
    flex-shrink: 0;
}

.store-card-details {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-right: 5px;
    width: 115px;
    height: 81px;
    flex-shrink: 0;
}

.store-card-title {
    font-size: 0.9rem;
    margin-left: 6px;
    margin-top: 9px;
}

.store-card-price {
    font-size: 0.8rem;
    margin: 0px;
    margin-left: 6px;
}

.store-card-buttons {
    display: flex;
    flex-direction: column;
    padding: 5px;

}

.store-card-button {
    background-color: #007bff;
    color: rgb(8, 7, 7);
    border: none;
    cursor: pointer;
    border-radius: 12px;
    border: 1px solid white;
    font-size: 10px;
    /* width: 55px; */
    height: 25px;
    flex-shrink: 0;
}

.store-card-button i {
    margin: 4px;
}

.store-card-button:hover {
    background-color: #0056b3;
}