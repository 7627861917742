.admin-section {
    display: flex;
    height: 100vh;
    overflow: hidden;
}

.search-datail-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.admin-content {
    flex-grow: 1;
    padding: 20px;
    color: #333;
    display: flex;
    justify-content: space-evenly;
    overflow: auto;

}

.admin-content-dashboardd {
    width: 50%;


}

.dashboard-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
}








