.hero {
    background-color: #ffffff;
    color: rgb(17, 16, 16);
    text-align: center;
    padding: 100px 0;
}

.hero h1 {
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    font-size: 75px;
    margin: 0;
}

.subtext {
    font-family: "Roboto", sans-serif;
    font-size: 20px;
    margin-top: 20px;
}

@media screen and (max-width: 749px) {


    .hero h1 {
        font-size: 42px;
        margin: 0;
    }

    .subtext {
        font-size: 15px;
        margin-top: 15px;
    }


}