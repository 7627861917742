@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,400;9..40,500;9..40,600;9..40,700&display=swap');

.card-imgs{
    width: 180px;
    height: 160px;
    border-radius: 20px;
}
.cards{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.card-name{
    color: #000;
    text-align: center;
    font-family: 'DM Sans', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 155.556% */
}
p{
    font-size: 12px;
}
.card-location{
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #000;
    text-align: center;
    font-family: DM Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 175% */
}
.card-name1{
    color: rgba(0, 0, 0, 0.50);
    text-align: center;
    font-family: DM Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 175% */
    /* color: #979797; */
}
.card-price{
    color: black;
    font-weight: 700;
    font-size: 15px;
}