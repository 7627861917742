.text-wrapper {
  color: #000000;
  font-family: "Roboto", sans-serif;
  font-size: 19px;
  font-weight: 500;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  top: 0;
}

.vector {
  height: 18px;
  left: 0;
  top: 0;
  text-align: center;
  width: 26px;
}

.blog-poster {
  display: flex;
  /* border: 2px solid red; */
  flex-direction: column;
  width: 30%;
  margin-right: 90px;
  margin-top: 70px;
}
.blog-poster div img{
  width: 100%;
  margin-bottom: 20px;
}

.mainDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.art {
  width: 60%;
  margin-top: 50px;
  border-radius: 10px;
}

@media screen and (max-width: 992px) {
  .searchFilter {
    width: 40%;
    margin-right: -30px;
    margin-top: 50px;
  }
}

@media screen and (max-width: 749px) {
  .searchFilter {
    width: 60%;
    margin-right: 0px;
    margin-top: 50px;
  }

  .text-wrapper {
    color: #000000;
    font-family: "Roboto", sans-serif;
    font-size: 19px;
    font-weight: 500;
    left: 0;
    letter-spacing: 0;
    line-height: normal;
    top: 0;
  }

  .mainDiv {
    display: flex;
    flex-direction: column-reverse;
    gap: 2vh;
    align-items: center;
    justify-content: center;
  }

  .art {
    width: 90%;
  }

  .innerDiv {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 400px) {
  .searchFilter {
    width: 80%;
    margin-right: 0px;
    margin-top: 50px;
  }
}