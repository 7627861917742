/* CSS for the ArticleSection component */
@import url("https://fonts.googleapis.com/css?family=DM+Sans:400,500,700|Source+Sans+Pro:300,400,600,700&display=swap");

.latest-blog {
    width: 100%;
    margin: 70px 0 150px 0;
}

.latest-blogs {
    margin: 50px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
}

.latest-blog-h2 {
    font-weight: bold;
    font-size: 38px;
    text-align: center;
    margin: 20px 0;
}

.latest-blog1 {
    max-width: 300px;
}

.latest-blog-section {
    background-color: #fff;
    width: 100%;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    padding: 20px 25px;
    margin: 20px 0;
    position: relative;
}

.latest-blog-section a {
    color: black;
    text-decoration: none;
}

.latest-blog-section .article-span {
    font-weight: bold;
    padding-bottom: 3px;
    border-bottom: 3px solid green;
}

.latest-blog-section .article-span:hover {
    color: grey;
}

.latest-blog-section h2 {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 10px;
}

.latest-blog-section p {
    font-size: 0.8rem;
    line-height: 1.4;
    margin-bottom: 15px;
}


.latest-blog-image {
    max-width: 50%;
    height: auto;
    margin-bottom: 10px;
    display: flex;
}






/* Responsive Styles */
@media (max-width: 770px) {
    .latest-blog1 {
        max-width: 225px;
    }

    .latest-blog-section h2 {
        font-size: 0.8rem;
        margin-bottom: 6px;
    }

    .latest-blog-section p {
        font-size: 0.7rem;
        margin-bottom: -35px;
    }


    .latest-blog-section .article-span {
        font-size: 11px;
    }

    .latest-blog-h2 {
        font-size: 28px;
    }

}